.App{
    text-align: center;
    text-transform: uppercase;
}

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.overnav {
    /* display: inline-block; */
    
    position: fixed;
    float: left;
    /* margin-right: 85%; */
    width: 15%;
    height: 100%;
    left: 0;
}

.headerwrap{
    /* position:absolute */
    margin: 3em 0 2em 2.5em;
    /* left: 2.5em;
    top: 3em; */

}

.header{
    font-size: 0.9em;
    font-weight: 600;
    clear: left;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    max-width: 25em;
    margin: 0;

}

.credit{
    position: absolute;
    left: 2.5em;
    bottom: 1.875em;
}
.credit p {
    font-size: 0.6em;
    font-weight: 600;
    clear: left;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    max-width: 25em;
    margin: 0;
}

.navBar{
    color:black;
    text-align: left;
    clear: left;
    /* position: absolute; */
    margin-left: 2.5em;
    /*top: 5em; */
}

.navBar ul{
    list-style: none;
    font-family: Arial, Helvetica, sans-serif;
    /* font-size: 10px; */
    font-size: 0.7em;
    font-weight: 600;
    /* display: inline-block; */
    padding-left: 0;
}

.navBar ul li{
    padding-bottom: 0.3125em;
    clear: both;
    float: left;
}

.navBar ul li:hover{
    text-decoration: underline;
    color: gray;
}

.navBar ul li a{
    text-decoration: none;
    color: black;
}

.navBar ul li a:hover{
    text-decoration: underline;
    color: gray;
}

.container{
    /* padding-top: 100px; */
    margin-left: 15%;
    margin-right: 3%;
    padding-top: 3em;
    /* padding-top: 2.5%; */
    /* display: flex; */
    /* column-count: 4;
    column-gap: 1em;
    direction: ltr; */
}

.pics {
    /* display: inline-block; */
    margin: 0 0 1em;
    max-width: 100%;
    object-fit: scale-down;
    /* padding: 1%; */
}

.picsdiv {
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -10; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 1.875em;
    max-width: 30%;
    /* background-clip: padding-box; */
}
.baricon {
    display: none;
}
#toggle {
    display: none;
}

.aboutwrap {
    margin: 0 5% 5% 20%;
}

.aboutheader{
    margin: 0;
    text-align: center;
    padding: 1em 0 1em;
}

.aboutpic {
    width: 35%;
    float: left;
    padding-bottom: 1em;
    display: inline-block;
}

.abouttext {
    text-align: left;
    margin: 0;
    display: inline-block;
    width: 50%;
    text-transform: none;
}

@media screen and (max-width: 1100px){
    .my-masonry-grid_column {
        padding-left: 1.875em;
        max-width: 50%;
        /* background-clip: padding-box; */
    }
    .container{
        margin-left: 15%;
        /* margin-left: 160px; */

    }
}

@media screen and (max-width: 800px){
    .credit{
        display: none;
        position: unset;
    }
    .navBar, .headerwrap{
        position: unset;
    }
    .my-masonry-grid_column {
        padding-left: 0;
        max-width: 100%;
        /* background-clip: padding-box; */
    }
    .container{
        /* margin-left: 160px; */
        /* margin-left: 5em; */
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3.6875em;
        padding: 0;
    }
    .pics{
        margin: 0 0.3125em 0.3125em;
        max-width: 90%;
        height: auto;
        object-fit: contain;
    }
    .baricon {
        display: block;
        margin-right: 0.9375em;
        margin-top: 0.3125em;
    }
    .overnav{
        top: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        padding: 0.9375em 0;
        background-color: #ffffff;
        width: 100%;
        height: auto;
    }
    .header{
        font-size: 10px;
        font-weight: 600;
        text-align: left;
        font-family: Arial, Helvetica, sans-serif;
        /* box-sizing: border-box; */
        /* max-width: 400px; */
        margin: 0;
        padding: 0;
    }
    .headerwrap{
        margin: 0.5625em 0;
        padding: 0 0.9375em;
    }
    .navBar {
        position: absolute;
        background-color: #c9c9c9;
        top:3.6875em;
        left:0;
        width: 100%;
        text-align: center;
        margin: 0;
        /* padding-bottom: 10px; */
    }
    .navBar ul {
        padding: 0;
        margin: 0;
        max-height: 0;
        overflow: hidden;
        font-size: 10px;
        /* transition: all 0.5s; */
    }
    .navBar ul li {
        padding: 0.625em .9375em;
        float: none;
    }
    .navBar ul li a {
        display: inline-block;
        /* padding: 15px; */
    }
    #toggle:checked ~ nav ul {
        max-height: 12.5em;
        padding: .9375em 0;
        transition: all 0.5s;
    }

}